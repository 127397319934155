<template>
    <div class="wrap">
       <div class="info-list">
            <ul v-bind:key="key"  v-for="(item,key) in dataList">
            <!-- <router-link :to="{path:'./manual',params:{id:item.id}}"> 
                <li><span>{{item.name}}</span> <span>更多>></span></li>
            </router-link>-->
           <li @click="clickname(item.id)"><span>{{item.name}}</span> <span>更多>></span></li>
            </ul>
       </div>
    </div>
</template>
<script>

export default {

     data() {
      return {
        dataList:[
            {
                name:'中国工商银行B2B银行操作手册',
                id:0
            },
            {
                name:'广大银行B2B银行操作手册',
                id:1
            },
            {
                name:'华夏银行B2B银行操作手册',
                id:2
            },
            {
                name:'建设银行B2B银行操作手册',
                id:3
            },
            {
                name:'民生银行B2B银行操作手册',
                id:4
            },
            {
                name:'农业银行B2B银行操作手册',
                id:5
            },
            {
                name:'平安银行B2B银行操作手册',
                id:6
            },
             {
                name:'浦发银行B2B银行操作手册',
                id:7
            },
            {
                name:'邮政银行B2B银行操作手册',
                id:8
            },
             {
                name:'招商银行B2B银行操作手册',
                id:9
            },
            {
                name:'中国银行B2B银行操作手册',
                id:10
            },
            {
                name:'中信银行B2B银行操作手册',
                id:11
            },
            
        ]
      };
    },
    methods: {
        clickname(code){
            this.$router.push({ 
                path: './manual', 
                query: { userId: code }
            })
        }
    }
};
</script>
<style lang="less">
.info-list{
    width:70%;
    margin:40px auto;
}
.info-list li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 0;
         &:hover{
            color:#ee2507;
            text-decoration: underline;
            cursor: pointer;
        }
   
}
</style>